import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import { ErrorBoundary } from 'wsm-error-boundary';
import loadShift from '../utils/loadShift';

import DataMapper from '../components/DataMapper';
import LegacyTracking from '../components/LegacyTracking';

let timeout;

/**
 *
 * @returns get the type of the page, currently used to load page specific mappings, can be built out further in the future
 */
const getPageType = () => {
	let pageType = '';
	if (typeof window !== 'undefined') {
		const { isVdp, isVlp } = window?.DDC?.dataLayer?.page?.pageInfo || {};
		if (isVdp) {
			pageType = 'vdp';
		} else if (isVlp) {
			pageType = 'srp';
		}
	}
	return pageType;
};

const hasNativeProject = (mapping) =>
	typeof Object.values(mapping).find(
		(val) => typeof val.legacy === 'undefined'
	) !== 'undefined';
export class WidgetContainer extends React.Component {
	componentDidMount() {
		window.DDC = window.DDC || {};
		window.DDC.WidgetData = window.DDC.WidgetData || {};
		window.DDC.WidgetData['ws-tagging'] =
			window.DDC.WidgetData['ws-tagging'] || {};
		window.DDC.WidgetData['ws-tagging'].mapping = this.props.mapping;
		window.dispatchEvent(new Event('ws-tagging-ready'));
	}

	componentWillUnmount() {
		clearTimeout(timeout); // added to pass a unit test but also makes sure setState doesn't run when component unmounted
		delete window.DataLayerHelper;
	}

	render() {
		let component;
		if (typeof window !== 'undefined' && window?.DDC?.isComposer) {
			component = null;
		} else {
			loadShift(this.props.legacyConfigurations);

			component = (
				<ErrorBoundary newRelicPageAction="ws-tagging error boundary">
					<div data-testid="Widget">
						{hasNativeProject(this.props.mapping) && (
							<DataMapper
								mapping={this.props.mapping}
								pageAlias={this.props.pageAlias}
								pageType={getPageType()}
							/>
						)}
						{this.props.legacyConfigurations?.length > 0 && (
							<LegacyTracking
								legacyConfigurations={
									this.props.legacyConfigurations
								}
							/>
						)}
					</div>
				</ErrorBoundary>
			);
		}
		return component;
	}
}

WidgetContainer.propTypes = {
	legacyConfigurations: PropTypes.arrayOf(PropTypes.object),
	mapping: PropTypes.objectOf(PropTypes.object),
	pageAlias: PropTypes.string
};

const mapStateToProps = ({
	legacyConfigurations,
	mapping,
	widgetInfo: { pageAlias }
}) => ({
	legacyConfigurations,
	mapping,
	pageAlias
});

const mapDispatchToProps = (_dispatch) => ({});

const Widget = connect(mapStateToProps, mapDispatchToProps)(WidgetContainer);

export default Widget;
