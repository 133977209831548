import getEnv from './getEnv';

let sdLoaded = false;

const loadShift = (legacyConfigurations) => {
	// window check because this tries to render on the server
	// sdLoaded check because render runs twice
	if (typeof window !== 'undefined' && !sdLoaded) {
		const data = {};
		if (legacyConfigurations) {
			legacyConfigurations.forEach((legacyConfiguration) => {
				if (legacyConfiguration.project === 'shift') {
					data.scriptLocation =
						getEnv() === 'prod'
							? legacyConfiguration.liveScriptLocation
							: legacyConfiguration.defaultScriptLocation;
					data.scriptObjectName =
						legacyConfiguration.scriptObjectName;
				}
			});
		}

		if (Object.keys(data).length > 0) {
			// this.log.info(`injecting ${this.scriptLocation} into page`);
			/* eslint-disable func-names,no-param-reassign,no-unused-expressions,prefer-rest-params,no-sequences,prefer-destructuring */
			(function (i, s, o, g, r, a, m) {
				i[`ShiftAnalyticsObject${r !== 'sd' ? r.toUpperCase() : ''}`] =
					r;
				(i[r] =
					i[r] ||
					function () {
						(i[r].q = i[r].q || []).push(arguments);
					}),
					(i[r].l = 1 * new Date());
				(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
				a.async = 1;
				a.src = g;
				m.parentNode.insertBefore(a, m);
			})(
				window,
				document,
				'script',
				data.scriptLocation,
				data.scriptObjectName
			);
			/* eslint-enable func-names,no-param-reassign,no-unused-expressions,prefer-rest-params,no-sequences,prefer-destructuring */
			sdLoaded = true;
		}
	}
};

export default loadShift;
